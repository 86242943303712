html,
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.slider_text{
    direction: ltr;
    padding-left: 2%;
}

.sliderBlock{
    overflow: hidden;
    position: relative;
}

.owl-dot{
    display: none !important;
}

.il{
    display: inline-block;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}



.owl-prev{
    /* background-color: transparent !important; */
    background-image: url(../../../../../assets/images/sliderImages/left.png) !important;
    background-repeat: no-repeat !important;
    background-size: 50% !important;
    color: transparent !important;
    display: block;
    height: 80px !important;
    left: 0;
    position: absolute !important;
    top: 40% !important;
    transition: all 0.5s !important;
    width: 80px !important;
    z-index: 10 !important;
    background-position: center;
    visibility: hidden;

  }
  
  .owl-next{
    background-color: transparent !important;
    background-image: url(../../../../../assets/images/sliderImages/right.png) !important;
    background-repeat: no-repeat !important;
    background-size: 50% !important;
    color: transparent !important;
    display: block;
    height: 80px !important;
    right: 0;
    position: absolute !important;
    top: 40% !important;
    transition: all 0.5s !important;
    width: 80px !important;
    z-index: 10 !important;
    background-position: center;
  }












.sliderPart {
    z-index: 10;
    /* background-color: green; */
    /* background-image: url(../../../../../assets/images/sliderImages/sliderImage1.jpg); */
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: 'Roboto', sans-serif;
    background-position: right;
    position: relative;
    height: 900px;
    width: 100%;
    overflow: hidden;
}



.sliderLeftButton:hover, .sliderRightButton:hover {
    cursor: pointer;
}

.textBackground{
    background-image: url(../../../../../assets/images/sliderImages/sliderTest.png);
    height: 1000px;
    width: 1200px;
    
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: -310px;
    bottom: -500px;
    z-index: 8;
   
}




.textPart {
    color: white;
    

}

.sliderHeader {
    position: absolute;
    left: 30%;
    top: 15%;
    right: 20%;
    height: 35%;
    width: 65%;
}

.sliderCompanyName {
    font-size: 28px;
    font-weight: inherit;
    font-family: 'Roboto', sans-serif;
    color: white;
    letter-spacing: 5px;
    padding: 0;
    margin: 0;
    font-weight: normal;
}



.infoSliderCompany {
    overflow: auto;
    color: white;
    font-family: 'Amiri', serif;
    font-size: 15px;
    height: 70%;
    overflow: auto;
    direction: rtl;
    padding-right: 25%;
}
.infoSliderCompany::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
.infoSliderCompany::-webkit-scrollbar-track {
    background: black; 
  }
   
  /* Handle */
.infoSliderCompany::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
.infoSliderCompany::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.rightPart1 {
    height: 750px;
    width: 700px;
    background-image: url(../../../../../assets/images/sliderImages/sliderTest.png); 
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    position: absolute;
    left: 100px;
    top: -550px;
    z-index: 8;
}

.rightPart2 {
    height: 100px;
    width: 100px;
    /* background-image: url(images/sliderPart3.png); */
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    position: absolute;
    top: -35px;
    left: 30%;
    z-index: 8;
}

.leftPart1 {
    height: 400px;
    width: 400px;
    /* background-image: url(images/sliderPart4.png); */
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    position: absolute;
    top: -150px;
    left: 40%;
    z-index: 8;
}

.leftPart2 {
    height: 1000px;
    width: 1000px;
    /* background-image: url(images/sliderPart5.png); */
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    position: absolute;
    top: -400px;
    right: -400px;
    z-index: 8;
}

.bottomPart1 {
    height: 500px;
    width: 500px;
    /* background-image: url(images/sliderPart1.png); */
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    position: absolute;
    bottom: -300px;
    right: 200px;
    z-index: 8;
}

.readMoreButton {
    color: white;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    height: 50px;
    line-height: 50px;
    width: 200px;
    display: inline-block;
    position: absolute;
    bottom: 30%;
    right: 20%;
    background: rgb(0,206,255);
    background: linear-gradient(90deg, rgba(0,206,255,1) 0%, rgba(0,0,0,1) 100%);
    border-radius: 90px;
    display: none;
}

.comTriangle {
    height: 500px;
    width: 500px;
    background-color: black;
    display: inline-block;
    position: absolute;
    top: -45%;
    left: -10%;
    border-radius: 120px;
    transform: rotate(45deg);
    z-index: 8;
}














.closePart {
    display: inline-block;
    position: absolute;
    top: 20%;
    left: 3%;
}


.lockPart::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(05,05,05,0);
    content: ' ';
    z-index: 8000000;
}

.sliderCompanyName{
    font-size: 28px;
    font-weight: normal;
}


.shortInfo{
    display: none;
    font-size: 14px;
    position: absolute;
    top: 36px;
}

#dialogue{
    position: absolute;
    top: -100%;
    display: grid;
    background-image: linear-gradient(150deg, #000000 15%, #00BEEB 41%);
    color: #FFFFFF;
    height: 100%;
    width: 100%;   
    z-index: 10000;
    animation-duration: 1s;  
    animation-fill-mode: forwards;   
    background-color: rgba(0,0,0,0.5);
    /* overflow: scroll; */
    -webkit-overflow-scrolling: touch;   
    transition: 0.2s;
}

.dialogue-outer_box{
    position: fixed;
    display: none; 
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    z-index: 10000000000; 
}

.close-box{
    display: inline-block;
    color:#FFFFFF;
    cursor: pointer;
    vertical-align: top;
    position: absolute;
    -webkit-font-smoothing: antialiased;
    font: normal normal normal 18px/1 FontAwesome;
    left: 308px;
    top: 4px;
}

.dialogueHeader{
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 100%;
    height: 10%;
}

.dialogueText{
    display: inline-block;
    padding: 5%;
    /* height: 90%; */
    overflow-y: auto;
    margin: 0;
    /* position: absolute;
    top: 70px; */
}

.body-part{
    overflow-y: auto;
}

.close-box:before{
    content: "\f00d";
}

.dialogueHeaderPart{
    padding: 0 5% 0 5%;
    /* position: fixed; */
    /* width: 60%; */
    padding-bottom: 0;
    z-index: 200000000;
    background-image: linear-gradient(150deg, #000000 34%, #00BEEB 115%);
}

.closeBlock{
    z-index:5010;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
    background-color: rgba(0,0,0,0.5);
    display: none;

}

@keyframes dialogueToClose {
    0% {top: 0px;
        position: absolute;}
    50% {top: 30px;
        position: absolute;}
    100% {top: -800px;
        position: absolute;}
}



@keyframes dialogueToShow {
    0% {top: -800px;
        position: absolute;}
    50% {top: 30px;
        position: absolute;}
    100% {top: 0px;
        position: absolute;}
}


@media screen and (max-width: 1864px){
    .com_img{
        height: 60px;
    }
}

@media screen and (max-width: 1630px){
    .com_img{
        height: 50px;
    }
}

@media screen and (max-width: 1500px){
    .company{
        width: 20%;
        padding: 0;
    }
    .ComText{
        width: 20%;
        padding: 0;
    }
    .comTriangle{
        display: none;
    }
}

@media screen and (max-width: 1370px){
    .sliderPart{
        background-size: 150%;
    }
    .divBeforePartners{
        height: 70px;
    }
}

@media screen and (max-width: 1320px){
    .readMoreButton{
        right: 10%;
    }
    .leftPart2{
        top: -500px;
        right: -500px;
    }
    .rightPart2{
        top: -40px;
        left: 32%;
    }
    .leftPart1{
        top: -200px;
        left: 40%;
    }
    .bottomPart1{
        bottom: -350px;
        right: 200px;
    }
    .textPart{
        height: -900px;
        width: -1100px;
    }
    .comTriangle{
        height: 450px;
        width: 450px;
        left: -15%;
    }
    .footTextHead{
        font-size: 26px;
    }
}

@media screen and (max-width: 1280px){
    .designTriangle1{
        left: -600px;
    }
    .designTriangle2{
        left: -650px;
    }
    .designTriangle3{
        left: -800px;
    }
    .shadowTriangle3{
        left: -900px;
    }
    .shadowTriangle2{
        left: -880px;
    }
    .shadowTriangle1{
        left: -860px;
    }
    .blueLines1{
        left: 0;
    }
    .blueLines2{
        left: 0;
    }
    .blueLines3{
        left: 10px;
    }
    .blueLines4{
        left: 100px;
    }
    .rightShadowTriangle1{
        right: -550px;
    }
    .rightShadowTriangle2{
        right: -500px;
    }
    .rightTriangle1{
        right: -400px;
    }
    .rightTriangle2{
        right: -170px;
    }
    .rightBlueLines2{
        right: 0;
    }
    .rightBlueLines1{
        right: 150px;
    }
}

@media screen and (max-width: 1170px){
    .textPart{
        height: 850px;
        width: 1150px;
    }
    .infoSliderCompany{                      
        height: 50%;
    }
    .bottomPart1{
        bottom: -250px;
        right: 100px;
        height: 400px;
        width: 400px;
    }
    .leftPart2{
        height: 900px;
        width: 900px;
    }
    .rightPart2{
        top: -20px;
        left: 250px;
        height: 50px;
        width: 50px;
    }
    .rightPart1{
        height: 550px;
        width: 500px;
        left: 0;
        top: -660px;
    }
    .sliderPart{
        height: 700px;
    }
}

@media screen and (max-width: 1050px){
    .bottomPart1{
        bottom: -150px;
        right: 5%;
        height: 300px;
        width: 300px;
    }
}

@media screen and (max-width: 1000px){
    .slider_title{
        font-size: 28px;
    }
    .designTriangle1{
        left: -700px;
    }
    .designTriangle2{
        left: -750px;
    }
    .designTriangle3{
        left: -900px;
    }
    .shadowTriangle1{
        left: -960px;
    }
    .shadowTriangle2{
        left: -980px;
    }
    .shadowTriangle3{
        left: -1000px;
    }
    .blueLines1{
        left: -60px;
    }
    .blueLines2{
        left: -60px;
    }
    .blueLines3{
        left: -50px;
    }
    .blueLines4{
        left: 40px;
    }
    .rightShadowTriangle1{
        right: -650px;
    }
    .rightShadowTriangle2{
        right: -600px;
    }
    .rightTriangle1{
        right: -500px;
    }
    .rightTriangle2{
        right: -270px;
    }
    .rightBlueLines2{
        right: -50px;
    }
    .rightBlueLines1{
        right: 50px;
    }
}

@media screen and (max-width: 980px){
    .textPart{
        height: 750px;
        width: 1050px;
        left: -310px;
        bottom: -400px;
    }
    .com_img{
        height: 50px;
    }
}

@media screen and (max-width: 900px){
    .ComText, .company{
        display: block;
        float: none;
        text-align: center;
        width: 100%;
    }
}


@media screen and (max-width: 870px){
    .leftPart1{
        top: -150px;
        left: 350px;
        height: 300px;
        width: 300px;
    }
    .slider_title{
        font-size: 26px;
    }
 
}

@media screen and (max-width: 858px){
    .textPart{
        width: 950px;
        left: -260px;
    }
}


@media screen and (max-width: 816px){
    .leftPart1{
        top: -110px;
        left: 350px;
        height: 200px;
        width: 200px;
    }

}

@media screen and (max-width: 800px){
    .comTriangle, .leftPart1{
        display: none;
    }
}

@media screen and (max-width: 780px){
    .bottomPart1{
        bottom: -25%;
        right: 5%;
        height: 40%;
        width: 30%;
    }
}

@media screen and (max-width: 750px){
    .sliderPart{
        height: 430px;
    }
    .leftPart2{
        top: -500px;
        right: -200px;
        height: 500px;
        width: 500px;
    }
    .rightPart1{
        height: 350px;
        width: 300px;
        left: 0;
        top: -230px;
    }
    .rightPart2{
        height: 50px;
        width: 50px;
        left: 150;
        top: -20px;
    }

    .textBackground{
        bottom:-570px;
        height: 950px;
        left: -300px;
        width:1050px;
    }

    .rightPart1{
        display: none;
    }
}

@media screen and (max-width: 700px){
    .bottomPart1{
        display: none;
    }
}




@media screen and (max-width: 698px){
    .textPart{
        width: 750px;
        left: -160px;
        bottom: -350px;
        height:650px;
    }
}

@media screen and(max-width: 620px){
    leftPart1{
        top: -110px;
        left: 300px;
        height: 200px;
        width: 200px;
    }
}

@media screen and (max-width: 600px){
    .leftPart2{
        display:none;
    }
}

@media screen and (max-width: 592px){
    .textPart{
        width: 650px;
        left: -160px;
        bottom: -300px;
        height: 550px;
    }
}


@media screen and (max-width: 500px){

    .textPart{
        width: 450px;
        left: -120px;
        bottom: -200px;
        height: 350px;
    }

    .slider_title{
        font-size: 22px;
        font-weight: normal;
        position: absolute;
        top: 0;
    }

    .sliderPart{
        height: 346px;
    }

    .readMoreButton{
        bottom: 20%;
        width: 150px;
        height: 30px;
        line-height: 30px;
    }

    .leftPart1{
        left: 250px;
    }

    .slider_text{
        display: none;
    }

    .shortInfo{
        display: inline-block;
    }
    .sliderHeader{
        height: 24%;
    }
    
}

@media screen and (max-width: 470px){
    .leftPart1{
        top: -80px;
        left: 200px;
        height: 150px;
        width: 150px;
    }
}


@media screen and (max-width: 450px){
    .leftPart1{
        top: -80px;
        left: 200px;
        height: 150px;
        width: 150px;
    }
}
@media screen and (max-width: 420px){
    .rightPart1{
        top: -190px;
        left: 0;
        height: 300px;
        width: 250px;
    }
    .rightPart2{
        top: -8px;
        left: 130px;
        height: 20px;
        width: 20px;
    }
   
}

@media screen and (max-width: 400px){
    .close-box{
        left: 230px;
    }

    .sliderPart{
        height: 300px;
    }
}

@media screen and (max-width: 380px){
    .leftPart1{
        top: -60px;
        left: 180px;
        height: 100px;
        width: 100px;
    }
}

@media screen and (max-width: 354px){
    .close-box{
        left: 214px;
    }
}

@media screen and (max-width: 330px){

    .textPart{
        width: 350px;
        left: -100px;
        bottom: -100px;
        height: 250px;
    }
    .slider_title{
        font-size: 20px;
        top: 12%;
    }
    .sliderHeader{
        width: 60%
    }

  
}




