@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.content{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: black;
    background-image: url("../../../assets/images/partnersBackgropund.png");
    position: relative;
}

.pageTitle{
    color: white;
    display: inline-flex;
    /* font-size: ; */
    justify-content: center;
    font-family: 'Fira Sans', sans-serif;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 5vh;
    margin-top: 200px;
}

.news_collaction{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    flex-wrap: wrap;
    order: 3;
}

.news_item{
    display: none;
    justify-content: space-between;
    flex-direction: column;
    width: 300px;
    height: 500px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 50px;
    margin-bottom: 50px;
}

.news_item_image_part{
    width: 100%;
    height: 37%;
    display: grid;
    place-items: center;
    background-image: url("../../../assets/images/news_image.jpeg");
    background-size: cover;
}

.news_item_text_part{
    background-color: white;
    height: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.news_item_text{
    font-family: 'Roboto Condensed', sans-serif;
    margin: 20px;
    margin-bottom: 0;
}

.news_item_read_more_button{
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    text-transform: capitalize;
    font-size: 20px;
    width: 80%;
    background: linear-gradient(90deg, rgba(0,206,255,1) 0%, rgba(0,0,0,1) 90%);
    border-radius: 180px;
    display: grid;
    place-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.newsArrows{
    width: 40px;
    position: absolute;
    display: none;
}

.leftArrow{
    left: 5px;
}

.rightArrow{
    right: 5px;
}
