*{
    padding: 0;
    margin: 0;
} 

.headerBlock{
    position: relative;
    background-image:  url('../../../../../assets/images/headerImages/headBack.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-position-x: 40%;
    background-position-y: 0px; 
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 10;
}

.headerDesignePart{
    position: relative;
    top: 120px;
}

.designTriangle1{
    width: 850px;
	height: 850px;
    background-size: cover;
    transform: rotate(45deg);
    position: absolute;
    top: -100px;
    left: -500px;
    border-radius: 180px;
    z-index: 6;
    -webkit-box-shadow: 0px 0px 40px 40px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 40px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 40px rgba(0,0,0,0.75);
    background: linear-gradient(45deg, rgba(0,0,0,1) 80%, rgba(20,20,21,1) 100%);
}

.designTriangle2{
    width: 1050px;
	height: 1050px;
    background-size: cover;
    transform: rotate(45deg);
    position: absolute;
    left: -500px;
    top: -200px;
    border-radius: 180px;
    z-index: 5;
    -webkit-box-shadow: 0px 0px 40px 40px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 40px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 40px rgba(0,0,0,0.75);
    background: linear-gradient(45deg, rgba(0,0,0,1) 80%, rgba(20,20,21,1) 100%);
}
.designTriangle3{
    width: 1250px;
	height: 1250px;
    background-size: cover;
    transform: rotate(45deg);
    position: absolute;
    left: -650px;
    top: -300px;
    border-radius: 180px;
    z-index: 4;
    background: linear-gradient(45deg, rgba(0,0,0,1) 80%, rgba(20,20,21,1) 100%);
}

.blueLines1 {
    height: 30px;
    width: 400px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,199,246,1) 76%);
    position: absolute;
    top: 750px;
    left: 190px;
    z-index: 10;
    transform: rotate(-45deg);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.blueLines2 {
    height: 50px;
    width: 600px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 25%, rgba(0,199,246,1) 76%);
    position: absolute;
    top: 670px;
    left: 190px;
    z-index: 9;
    transform: rotate(-45deg);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.blueLines3 {
    height: 30px;
    width: 500px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,199,246,1) 76%);
    position: absolute;
    top: 760px;
    left: 200px;
    z-index: 9;
    transform: rotate(-45deg);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.blueLines4 {
    height: 50px;
    width: 400px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 30%, rgba(0,199,246,1) 76%);
    position: absolute;
    top: 800px;
    left: 280px;
    z-index: 9;
    transform: rotate(-45deg);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.shadowTriangle1{
    width: 1350px;
	height: 1350px;
    background-size: cover;
    transform: rotate(-135deg);
    position: absolute;
    left: -700px;
    top: -350px;
    border-radius: 180px;
    background: rgba(255, 255, 255, 0.3);
    z-index: 3;
}

.shadowTriangle2{
    width: 1350px;
	height: 1350px;
    background-color: #39cccc;
    background-size: cover;
    transform: rotate(-135deg);
    position: absolute;
    left: -720px;
    top: -350px;
    border-radius: 180px;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
}

.shadowTriangle3{
    width: 1350px;
	height: 1350px;
    background-color: #39cccc;
    background-size: cover;
    transform: rotate(-135deg);
    position: absolute;
    left: -750px;
    top: -350px;
    border-radius: 180px;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
}

.linesParts{
    position: relative;
    top: 112px;
}

.rightShadowTriangle1{
    width: 1250px;
    height: 750px;
    background-color: #39cccc;
    background-size: cover;
    transform: rotate(-45deg);
    position: absolute;
    right: -450px;
    bottom: -300px;
    background: rgb(255,255,255);
    background: linear-gradient(-180deg, rgba(255,255,255,0.5) 1%, rgba(0,0,0,0.1) 5%);
    z-index: 2;
    -webkit-box-shadow: inset 0px 0px 214px -106px rgba(0,0,0,0.55);
    -moz-box-shadow: inset 0px 0px 214px -106px rgba(0,0,0,0.55);
    box-shadow: inset 0px 0px 214px -106px rgba(0,0,0,0.55);
}

.rightShadowTriangle2{
    width: 950px;
    height: 750px;
    background-color: #39cccc;
    background-size: cover;
    transform: rotate(-45deg);
    position: absolute;
    right: -400px;
    bottom: -300px;
    background: rgb(255,255,255);
    background: linear-gradient(-180deg, rgba(255,255,255,0.5) 1%, rgba(0,0,0,0.1) 5%);
    z-index: 2;
    -webkit-box-shadow: inset 0px 0px 214px -106px rgba(0,0,0,0.55);
    -moz-box-shadow: inset 0px 0px 214px -106px rgba(0,0,0,0.55);
    box-shadow: inset 0px 0px 214px -106px rgba(0,0,0,0.55);
}

.rightBlueLines1 {
    height: 60px;
    width: 400px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 30%, rgba(0,199,246,1) 76%);
    position: absolute;
    bottom: 0;
    right: 250px;
    z-index: 9;
    transform: rotate(-45deg);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.rightBlueLines2 {
    height: 40px;
    width: 500px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 30%, rgba(0,199,246,1) 76%);
    position: absolute;
    bottom: 0;
    right: 100px;
    z-index: 9;
    transform: rotate(-45deg);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.rightTriangle1 {
    width: 600px;
	height: 600px;
    background-color: #39cccc;
    background-size: cover;
    position: absolute;
    right: -300px;
    bottom: -350px;
    background: rgb(0,0,0);
    background: linear-gradient(-90deg, rgba(0,0,0,1) 3%, rgba(10,10,10,1) 89%);
    z-index: 4;
    transform: rotate(135deg);
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    border-radius: 50px;
}

.rightTriangle2 {
    width: 600px;
	height: 500px;
    background-size: cover;
    position: absolute;
    right: -70px;
    bottom: -350px;
    background: rgb(0,0,0);
    background: linear-gradient(-90deg, rgba(0,0,0,1) 30%, rgba(0,199,246,1) 76%);
    z-index: 3;
    transform: rotate(135deg);
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    border-radius: 90px;
}

.headerContentPart {
    color: white;
    position: absolute;
    top: 300px;
    left: 50px;
    z-index: 15;
}

.whatIs {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    padding: 0;
    margin: 0;
}
.companyName {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    padding: 0;
    margin-right: 12px;
}

.aboutCompany {
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    margin: 0;
    line-height: 2;
}

.companyText{
    display: block;
}


@media screen and (max-width: 1280px){
    .designTriangle1{
        left: -600px;
    }
    .designTriangle2{
        left: -650px;
    }
    .designTriangle3{
        left: -800px;
    }
    .shadowTriangle3{
        left: -900px;
    }
    .shadowTriangle2{
        left: -880px;
    }
    .shadowTriangle1{
        left: -860px;
    }
    .blueLines1{
        left: 0;
    }
    .blueLines2{
        left: 0;
    }
    .blueLines3{
        left: 10px;
    }
    .blueLines4{
        left: 100px;
    }
    .rightShadowTriangle1{
        right: -550px;
    }
    .rightShadowTriangle2{
        right: -500px;
    }
    .rightTriangle1{
        right: -400px;
    }
    .rightTriangle2{
        right: -170px;
    }
    .rightBlueLines2{
        right: 0;
    }
    .rightBlueLines1{
        right: 150px;
    }
}

@media screen and (max-width: 1000px){
    .designTriangle1{
        left: -700px;
    }
    .designTriangle2{
        left: -750px;
    }
    .designTriangle3{
        left: -900px;
    }
    .shadowTriangle1{
        left: -960px;
    }
    .shadowTriangle2{
        left: -980px;
    }
    .shadowTriangle3{
        left: -1000px;
    }
    .blueLines1{
        left: -60px;
    }
    .blueLines2{
        left: -60px;
    }
    .blueLines3{
        left: -50px;
    }
    .blueLines4{
        left: 40px;
    }
    .rightShadowTriangle1{
        right: -650px;
    }
    .rightShadowTriangle2{
        right: -600px;
    }
    .rightTriangle1{
        right: -500px;
    }
    .rightTriangle2{
        right: -270px;
    }
    .rightBlueLines2{
        right: -50px;
    }
    .rightBlueLines1{
        right: 50px;
    }
}

@media screen and (max-width: 900px){
    .aboutCompany{
        max-width: 270px;
    }

    .designTriangle2{left: -814px;}
    .designTriangle3{left: -974px;}
    .shadowTriangle1{left: -1040px;}
    .blueLines1{top: 780px;}
    .blueLines2{top: 700px;}
    .blueLines3{top: 790px;}
    .blueLines4{top: 860px;}
    .rightBlueLines1{bottom: -30px;}
    .rightBlueLines2{bottom: -30px;}
    .rightTriangle2{bottom: -380px;}

} 

@media screen and (max-width: 830px){
    .designTriangle1{left: -800px;}
    .designTriangle2{left: -850px;}
    .designTriangle3{left: -1000px;}
    .shadowTriangle1{left: -1060px;}
    .shadowTriangle2{left: -1080px;}
    .shadowTriangle3{left: -1100px;}
    .blueLines1{left: -160px;}
    .blueLines2{left: -160px;}
    .blueLines3{left: -150px;}
    .blueLines4{left: -60px;}
    .rightShadowTriangle1{right: -750px;}
    .rightShadowTriangle2{right: -700px;}
    .rightTriangle1{right: -600px;}
    .rightTriangle2{right: -370px;}
    .rightBlueLines1{right: -200px;}
    .rightBlueLines2{right: -200px;}
}


@media screen and (max-width: 698px){
    .textPart{
        width: 750px;
        left: -160px;
        bottom: -350px;
        height:650px;
    }

    .textBackgrouns{
        height:924px;
        width: 866px;
    }

    .sliderHeader{
        left: 38%;
        height: 26%;
    }
}

@media screen and (max-width: 630px){
    .rightShadowTriangle1{right: -850px;}
    .rightShadowTriangle2{right: -800px;}
    .rightTriangle1{right: -700px;}
    .rightTriangle2{right: -470px;}
    .rightBlueLines1{right: -150px;}
    .rightBlueLines2{right: -300px;}

    .headerDesignePart{
        left: -160px;
    }

    .linesParts{
        left: -180px;
    }

    .textBackgrouns{
        height:860px;
        width: 810px;
    }

    .sliderHeader{
        left: 40%;
        height: 20%;
    }
}

@media screen and (max-width: 530px){
    .rightShadowTriangle1{right: -950px;}
    .rightShadowTriangle2{right: -900px;}
    .rightTriangle1{right: -800px;}
    .rightTriangle2{right: -570px;}
    .rightBlueLines1{right: -250px;}
    .rightBlueLines2{right: -400px;}
}

@media screen and (max-width: 500px){
    .headerDesignePart{                      
        top: 0;
        left: -254px;
    }

    .linesParts{
        left: -150px;
        top: 176px;

    }

    .headerContentPart{
        top: 182px;
        left: 20px;    
    }

    .companyName{
        display: block;
        font-size: 24px;
    }
    .companyText{
        display:block;
        font-size: 10px;
    }
    .aboutCompany{
        max-width: 150px;
    }

    .designTriangle1{
        border-radius: 96px;
        left: -862px;
        top: -54px;
    }

    .designTriangle2{
        left: -1040px;
        top: -170px;
        border-radius: 96px; 
    }

    .designTriangle3{
        left: -1230px;
        top: -270px;
        border-radius: 100px;
    }
    .shadowTriangle1{
        border-radius: 96px;
        left: -1310px;
        top: -330px;
    }
    .shadowTriangle2{
        border-radius: 96px;
        left: -1322px;
        top: -322px;
    }

    .shadowTriangle3{
        border-radius: 96px;
        left: -1346px;
        top: -330px;
    }

    .blueLines1{
        height: 26px;
        left: -250px;
        top: 460px;
        width: 438px;
    }
    .blueLines2{
        top: 438px;
        left: -276px;
        height: 38px;
        width: 574px;
    }
    .blueLines3{
        top: 469px;
        left: -230px;
        height: 26px;
        width: 482px;
    }
    .blueLines4{
        top: 522px;
        left: -160px;
        height: 38px;
        width: 360px;
    }
    .rightShadowTriangle1{right: -1050px;}
    .rightShadowTriangle2{right: -1000px;}
    .rightTriangle1{right: -800px;}
    .rightTriangle2{right: -570px;}
    .rightBlueLines1{right: -250px;}
    .rightBlueLines2{right: -400px;}

}

@media screen and (max-width: 400px){
    .headerDesignePart{
        left: -310px;
        top: -40px;
    }

    .linesParts{
        left: -246px;
    }

    .right_part{
        display: none;
    }
}

@media screen and (max-width: 330px){
    .companyName{
        font-size: 21px;
    }
    .aboutCompany{
        padding: 10px 0 0 0;
    }
}
