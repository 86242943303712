html,
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.companies {
    z-index: 10;
    height: 200px;
    width: 100%;
    background-color: #000000;
    position: relative;
    /* top: 0;
    left: 0; */
}

.ComText {
    font-family: 'Roboto', sans-serif;
    color: white;
    height: 100%;
    /* top: 40%; */
    /* padding-left: 50px;
    padding-right: 100px; */
    float: left;
    font-size: 28px;
    text-align: center;
    line-height: 180px;
}

.company {
    float: left;
    padding-left: 50px;
    padding-right: 100px;
    height: 100%;
    line-height: 250px;
    position: relative;
}

.com_img {
    height: 80px;
}

@media screen and (max-width: 1864px){
    .com_img{
        height: 60px;
    }
}

@media screen and (max-width: 1630px){
    .com_img{
        height: 50px;
    }
}

@media screen and (max-width: 1500px){
    .company{
        width: 20%;
        padding: 0;
    }
    .ComText{
        width: 20%;
        padding: 0;
    }
}

@media screen and (max-width: 980px){
    .com_img{
        height: 50px;
    }
}

@media screen and (max-width: 900px){

    .companies{
        z-index: 100;
        height: 590px;

    }
    .company{
        display: block;
        width: 100%;
        text-align: center;
        line-height: 0;
        margin-bottom: 30px;
        height: 14%;
    }

    .ComText{
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 80px;
        line-height: 0;
        height: 0;
    }

} 

@media screen and (max-width: 780px){
    .ComText{
        font-size: 26px;
    }
}

@media screen and (max-width: 500px){   
    .comTextHeading{
        font-size: 24px;
    }    
}

