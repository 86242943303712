.footerPart {
    display: flex;
    justify-content: center;
    height: 500px;
    color: white;
    z-index: 20;
    text-align: center;
    background-color: rgba(0,0,0,1);
    /* -webkit-box-shadow: 0px -66px 61px 53px rgba(0,0,0,1);
    -moz-box-shadow: 0px -66px 61px 53px rgba(0,0,0,1);
    box-shadow: 0px -66px 61px 53px rgba(0,0,0,1); */
}

.footerParts {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 30%;
}

.footTextHead {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    margin: 0;
    padding: 0;
    margin-bottom: 3%;
    padding-top: 3%;
}

.footTextBody1 {
    padding: 0;
    margin: 0;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    text-align: left;
}

.footerElem {
    display: inline-block;
    width: 50%;
    border-bottom: 2px solid lightgrey;
    text-align: left;
    padding: 0;
    margin: 0;
    font-family: 'Amiri', serif;
    font-size: 20px;
    margin-left: 20%;
    position: relative;
    padding-left: 30px;
}

.footerLeft {
    font-size:24px !important;
    color:#00CEFF;
    font-weight: bold !important;
    margin-right: 5%;
}

.footerInfoDiv{
    display: inline-block;
    width: 100%;
    padding-left: 7%;
}

.contactPart {
    display: inline-block;
    width: 70%;
    height: 50px;
    text-align: left;
    position: relative;
}

.contactIcons {
    position: absolute;
    left: 0;
    top: 0;
}


.contactText {
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    left: 10%;
    top: 0;
    font-family: 'Amiri', serif;
    text-decoration: none;
    color: white;
    font-family: 'Roboto', sans-serif !important;
}

.noselect {
    -webkit-touch-callout: none; 
      -webkit-user-select: none; 
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
}

.telLink{
    text-decoration: none;
    color: unset;
}

.fa-angle-right{
    position: absolute;
    left:0;
    top:0;
}



@media screen and (max-width: 1655px){
    .footTextBody1{
        font-size: 15px;
    }
    .footerPart{
        height: 400px;
    }
}

@media screen and (max-width: 1366px){
    .footTextBody1{
        font-size: 10px;
    }
    .footerPart{
        height: 300px;
    }
    .footerElem{
        font-size: 15px;
    }
    .contactText{
        left: 20%;
    }
}

@media screen and (max-width: 1100px){
    .footerPart{
        height: 350px;
    }
}

@media screen and (max-width: 1070px){
    .footerPart{
        height: 400px;
    }
}

@media screen and (max-width: 990px){
    .footerElem{
        margin-left: 0;
    }
    .footTextBody1{
        font-size: 13px;
    }
}

@media screen and (max-width: 850px){
    .footerElem{
        margin-left: 20%;
        width: 60%;
    }
    .contactText{
        font-size: 12px;
    }
}

@media screen and (max-width: 640px){
    .footTextBody1{
        font-size: 11px;
    }
}

@media screen and (max-width: 624px){
    .contactText{
        font-size: 10px;
        left: 30%;
    }

    .footTextHead{
        font-size: 24px;
    }
}

@media screen and (max-width: 470px){
    .footerParts{
        display: block;
        height: 33%;
        width: 100%;
    }

    .footerPart{
        display: block;
        height: 800px;
        padding-left: 3%;
        padding-right: 3%; 
    }
    .footerElem{
        font-size: 15px;
        margin-left: 0;
        width: 70%;
    }
    .contactText{
        font-size: 12px;
        left: 12%;
    }
    .contactPart{
        width: 86%;
    }
}

@media screen and (max-width: 330px){
    .footerParts{
        vertical-align: top;
    }
    .footerPart{
        height: 1000px;
    }
    .footTextHead{
        font-size: 22px;
    }
}

@media screen and (max-width: 260px){
    .footerParts{
        height: 400px;
        vertical-align: top;
    }
    .footerPart{
        height: 1100px;
    }
}





