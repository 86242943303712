.paragraphDot{
    color: white;
    padding-top: 0;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: black;
}

.pageTitle{
    color: white;
    display: inline-flex;
    /* font-size: ; */
    justify-content: center;
    font-family: 'Fira Sans', sans-serif;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 5vh;
}

.content {
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
}



.page-title{
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 50px;
    color: white;
    text-align: center;
    font-weight: normal;
    margin-top: 50px;
}

.paragraph{
    text-align: left;
    color: white;
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-family: 'Amiri', serif;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
}

@media screen and (max-width: 1450px){
   .page-title{
       font-size: 46px;
   }
}

@media screen and (max-width: 1100px){
   .page-title{
       font-size: 38px;
       margin-top: 88px;
   }
}

@media screen and (max-width: 910px){
    .page-title{
        font-size: 34px;
    }
}
@media screen and (max-width: 500px){
    .page-title{
        font-size: 32px;
    }
}
 
 