.content{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-image: url("../../../assets/images/partnersBackgropund.png");
    position: relative;
}

.pageTitle{
    margin-top: 200px;
    color: white;
    display: inline-flex;
    /* font-size: ; */
    justify-content: center;
    font-family: 'Fira Sans', sans-serif;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 5vh;
}

.loc_map{
    height: 500px;
    width: 80%;
    margin-bottom: 50px;
}

.contact-part{
    padding: 10px;
    color: white;
    background-image: linear-gradient( 
        90deg
        , #000000 15%, #00BEEB 70%);
}

.contact-item{
    padding: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    position: relative;
    width: 170px;
}

.contact-text{
    font-family: 'Amiri', serif;
    padding: 0;
    margin: 0;
    display: inline-block;
    position: absolute;
    top: 20%;
    right: 0;
    color: white;
    text-decoration: none;
}