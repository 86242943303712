*{
    margin: 0;
    padding: 0;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
.navbar {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    transition: top 0.3s;
}
.logopart {
    width: 450px;
	height: 450px;
    background-size: cover;
    border-bottom-left-radius: 120px;
    transform: rotate(-45deg);
    position: absolute;
    top: -230px;
    left: -130px;
    background: rgb(0,190,235);
    background: linear-gradient(90deg, rgba(0,190,235,1) 0%, rgba(0,0,0,1) 93%);
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.75);
}
.logoImg{
    position: absolute;
    top: 300px;
    left: 100px;;
    height: 50px;
    transform: rotate(45deg);
}
.menu {
    position: relative;
    background-image: linear-gradient(150deg, #000000 15%, #00BEEB 70%);
    text-align: right;
}
.menuItem {
    display: inline-block; 
    text-align: center;
    color: white;
    padding: 0;
    overflow: hidden; 
    height: 120px;
    line-height: 120px;
    width: 198px;  
}

.openSmallMenu{
    display: none;
    position: absolute;
    top: 36%;
    right: 20px;
}

.fa-navicon{
    font-size:36px;
    color:white;
}

.menuItemText:hover {
    font-size: 28px;
    cursor: pointer;
}
.menuItemText {
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    transition: 0.1s;
    color: white;
    text-decoration: none;
}

@keyframes setHoverBigger {
    0%   {font-size: 25px;}
    20%   {font-size: 26px;}
    40%   {font-size: 27px;}
    60%   {font-size: 28px;}
    80%   {font-size: 29px;}
    100%   {font-size: 30px;}
} 

.menuToOpen {
    display: none;
    z-index: 5001;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    text-align: right;
    transition: 0.5s;
    overflow: scroll;
    background-color: rgba(0,0,0,0.5);
}

.openMenuItem {
    display: inline-block;
    text-align: left;
    padding: 10px;
    padding-left: 10px;
    color: black;
    padding-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    transition: 0.5s;
    background-color: #00BEEB;
    width: 94%;
    height: 4%;
    margin: 10px;
    margin-left: 0;
    margin-right: 0;
    line-height: 100%;
    position: relative;
}


.openMenuNav {
    display: none;
    width: 40%;
    height: 100%;
    padding-left: 5px;
    background: radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,206,255,1) 100%);
    position: fixed;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    z-index: 10000;
    cursor: pointer;
}

/* .fa-navicon:before{
    content: "\f0c9";
} */

@keyframes fromRightToLeft{
    0% {right: -300px;}
    100% {right: 0;}
}

@keyframes fromLeftToRight{
    0% {right: 0;}
    100% {right: -300px;}  
}

@keyframes fromLeftToDeepRight{
    0% {right: 0;}
    100% {right: -500px;}  
}


.menuBrdDiv {
    width: 100%;
    height: 100%;
    background-color: black;
    text-align: right;
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.menuNavBar{
    transition: top 0.3s;
    height: 92px;
}

.close-block{
    display: inline-block;
    color: #00BEEB;
    cursor: pointer;
    vertical-align: top;
    position: absolute;
    -webkit-font-smoothing: antialiased;
    font: normal normal normal 20px/1 FontAwesome;
    left: 6%;
    top: 1.5%;
}

.openMenuItem{
    border-top-left-radius: 20px;
}

.fa-angle-right{
    position: absolute;
    right: 5%;top: 30%;
}

/* .fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */

.smallMenuLine{
    border: 2px solid #FFF;
    margin-bottom: 4px;
    width: 24px;
    
}

.dotes{
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('../../../../assets/images/headerImages/bottomPoints.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 50%;
    z-index: 1;
    height: 700px;
}

@media screen and (max-width: 1390px){
    .openSmallMenu{
        display: block;
        float: right;
    }
    .menuItem{
        display: none;
    }
    .menu{
        height: 92px;
    }
}

@media screen and (max-width: 1580px){
    .menuItem{
        width: 180px;
    }
}

@media screen and (max-width: 1470px){
    .menuItem{
        width: 160px;
    }
}
@media screen and (max-width: 1390px){
   .logoPart{
       height: 420px;
   }
}

@media screen and (max-width: 926px){
    .logoImg{
        left: 90px;
        top: 264px;
    }

    .logopart{
        height: 416px;
        left: -130px;
        top: -230px;
        width: 408px;
    }

    .menu{
        height: 70px;
    }

    .menuNavBar{
        height: 70px;
    }
}

@media screen and (max-width: 780px){
    .menu{
        height: 60px;
    }
    .menuNavBar{
        height: 60px;
    }
    .openSmallMenu{
        top: 32%;
    }
}

@media screen and (max-width: 600px){
    .openMenuNav{
        width: 44%;
    }

    /* .logoImg{
        top:250px;
    } */
}

@media screen and (max-width: 500px){
    /* .logoImg{
        height: 36px;
    } */
    .fa-navicon{
        font-size: 30px;
    }
    .openMenuNav{
        width: 52%;
    }

    .logoImg{
        /* height: 28px;
        top:256px;
        left: 62px; */
        height: 28px;
        left: 24px;
        top: 304px;
    }

    .logopart{
        /* width: 292px;
        height: 325px; */
        border-bottom-left-radius: 64px;
        height: 374px;
        left: -112px;
        top: -256px;
        width: 168px;
    }
}

@media screen and (max-width: 400px){
    .openMenuNav{
        width: 58%;
    }
}

@media screen and (max-width: 330px){
    .openMenuNav{
        width: 64%;
    }
}


@media screen and (max-width: 300px){
    .fa-navicon{
        font-size: 30px;
    }
}

