.ourPartnersPartText {
    position: relative;
    z-index: 10;
    height: 100px;
    width: 100%;
    background-color: #000000;
}

.ourPartnersTextInside {
    color: white;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    right: 5%;
    top: 40%;
    font-size: 28px;
}

.ourPartnersPart {
    position: relative;
    height: 800px;
    width: 100%;
    overflow: hidden;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 60%, rgba(45,45,45,1) 90%);
    background-repeat: no-repeat;
    background-position: top;

    z-index: 10;
}

.ourPartnersPart::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(05,05,05,0.5);
    background-repeat: no-repeat;
    background-position: top;
    content: ' ';
}


@media screen and (max-width: 900px){

    .ourPartnersTextInside {
        color: white;
        font-family: 'Roboto', sans-serif;
        position: inherit;
        right: 0;
        top: 0;
        font-size: 26px;
        text-align: center;
        padding-top: 30px;
    }
    

}

@media screen and (max-width: 500px){

    .ourPartnersTextInside{
        font-size: 24px;
    }  
}