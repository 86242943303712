.search {
    margin-top: 50px;
    border-radius: 90px;
    border: none;
    padding: 10px;
    padding-right: 10px;
    padding-left: 10px;
    width: 200px;
    height: 20px;
    background-image: url(../../../../../assets/images/searchButton.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 10%;
    background-origin: content-box;
}

/* .searchIconBox{
    position:absolute;
    top:166px;
    left: 200px;
    color: #C8C8C8;
    background-color: none;
    border: none;
} */





@media screen and (max-width: 500px){
  
    .search{
        width: 108px;
        margin-top: 20px;
    }
}