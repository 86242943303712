.content{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-image: url("../../../assets/images/partnersBackgropund.png");
    position: relative;
}

.pageTitle{
    color: white;
    display: inline-flex;
    /* font-size: ; */
    justify-content: center;
    font-family: 'Fira Sans', sans-serif;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 5vh;
}

.jobs_switch_part{
    width: 500px;
    display: inline-flex;
    justify-content: space-between;
    position: relative;
}

.jobs_switch_part_text{
    font-family: 'Fira Sans', sans-serif;
    display: inline-flex;
    font-size: 40px;
    color: white;
}

.jobs_switch_part_switcher_part{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: -10px;
    
}

.jobs_switch_part_switcher{
    display: inline-flex;
    margin-left: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    transition: 0.2s;
}

.right_jobs_switch_part_switcher{
    margin-left: 50%;
}

.list_part{
    display: flex;
    flex-direction: column;
}

.disactive_list_part{
    display: none;
}

.list_item{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 600px;
    /* height: 300px; */
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
}

.list_item_logo_part{
    
    width: 20%;
    /* height: 100%; */
    display: flex;
    align-items: center;
}

.list_item_logo{
    width: 100%;
}

.list_item_text_part{
    width: 75%;
    display: flex;
    flex-direction: column;
    color: white;

}

.list_item_text_part_date{
    text-align: right;
    font-size: 13px;
}

.list_item_text_part_job_title{
    font-size: 30px;
    text-align: left;
}

.list_item_text_part_job_short_info{
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
}

.deadLineIcon{
    font-size: 13px;
    margin-right: 10px;
}

.jobs_part_item{
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-top: 50px;
}

.jobs_part_item_text_part{
    display: flex;
    justify-content: space-between;
}

.jobs_part_item_text{
    font-size: 16px;
    color: white;
}

.jobs_part_down_button{

    color: white;
    font-size: 16px;
}

/* .jobs_part_item_text_part:hover .jobs_part_item_menu{
    max-height: 500px !important;
    transition: max-height 0.25s ease-in;
}

.jobs_part_item_menu{
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    background-color: red;
} */

/* https://dzone.com/articles/pure-css-slide-down-animation-1 */

.active_jobs_part_item_menu{
    height: initial !important;
}

.jobs_part_item_menu{
    transition: all .5s ease-in-out;
    height: 0;
    overflow: hidden;
    background-color: red;
}


.jobs_part_item_menu_li{
    color: white;
}
